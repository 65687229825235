@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'FranklinGothicBookRegular';
    /* src: url('webfont.eot'); */ /* IE9 Compat Modes */
    src: /* url('webfont.eot?#iefix') format('embedded-opentype'), */ /* IE6-IE8 */
         /* url('webfont.woff') format('woff'), */ /* Modern Browsers */
         url('./fonts/Franklin-Gothic-Book-Regular.ttf')  format('truetype'); /* Safari, Android, iOS */
         /* url('webfont.svg#svgFontName') format('svg'), */ /* Legacy iOS */
  }
}
